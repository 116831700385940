import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '80218af2dc7141a81880',
    cluster: 'mt1', // Use the cluster specified in your .env file
    forceTLS: false,
    wsHost: window.location.hostname,
    wsPort: 6001,
    disableStats: true,
});


import "./app.css";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
